var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"query-ct"},[_c('header',[_c('slogan'),_c('a',{staticClass:"main",attrs:{"href":"/"}})],1),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"query"},[_c('div',{staticClass:"query-row"},[_vm._m(0),_c('div',{staticClass:"md"},[_c('input',{directives:[{name:"autowidth",rawName:"v-autowidth",value:({
            maxWidth: '540px',
            minWidth: '540px',
            comfortZone: 0,
          }),expression:"{\n            maxWidth: '540px',\n            minWidth: '540px',\n            comfortZone: 0,\n          }"},{name:"model",rawName:"v-model",value:(_vm.orderId),expression:"orderId"}],attrs:{"type":"text","placeholder":"输入订单号"},domProps:{"value":(_vm.orderId)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.startQuery.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.orderId=$event.target.value}}}),_c('button',{class:{ light: _vm.fetching },on:{"click":_vm.startQuery}},[(!_vm.fetching)?_c('span',[_vm._v("查询")]):_c('spinner',{attrs:{"type":"light"}})],1)]),_vm._m(1)])]),_c('div',{staticClass:"result"},[(_vm.resList && _vm.resList.length > 0)?_vm._l((_vm.resList),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('div',{staticClass:"item-tp"},[_c('div',{staticClass:"lf"},[_c('div',{staticClass:"status-dot",class:{
                unpaid: item.state - 0 === 0,
                paid: item.state - 0 === 1,
                saving: item.state - 0 === 2,
                handling: item.state - 0 === 3,
                generated: item.state - 0 === 4,
                successed: item.state - 0 === 5,
                failed: item.state - 0 >= 50,
              }}),_c('p',{staticClass:"state"},[_vm._v(_vm._s(_vm.handleItemState(item.state)))])]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate(item.create_time)))])]),_c('div',{staticClass:"item-md"},[_c('span',{staticClass:"product-name"},[_vm._v(_vm._s(item.memo || "未命名"))])]),_c('div',{staticClass:"item-bt"},[_c('div',{staticClass:"lf"},[_c('p',{staticClass:"type"},[_vm._v("文件类型："+_vm._s(_vm._f("fileType")(item.file_type)))]),_c('p',{staticClass:"id"},[_vm._v(_vm._s(item.order_id))])]),_c('div',{staticClass:"rt"},[(item.state - 0 === 0)?[_c('button',{staticClass:"check",on:{"click":function($event){return _vm.triggerPay(item.order_id)}}},[_c('span',[_vm._v("去支付")])])]:[_c('button',{staticClass:"check",on:{"click":function($event){return _vm.jumpProof(item)}}},[_c('span',[_vm._v("查看证据")])]),_c('button',{staticClass:"download",on:{"click":function($event){return _vm.downCertificate(item.file_url, item.state)}}},[_c('img',{attrs:{"src":require("@/assets/images/download.svg"),"alt":"装饰","title":"下载"}})])]],2)])])}):_c('p',{staticClass:"empty"},[_vm._v("未找到查询记录")])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lf"},[_c('img',{staticClass:"decoration desk",attrs:{"src":require("@/assets/images/category.png"),"srcset":require("@/assets/images/category@2x.png") + " 2x","alt":"装饰"}}),_c('span',{staticClass:"text"},[_vm._v("订单查询")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rt"},[_c('a',{staticClass:"how-text",attrs:{"href":"https://mbd.pub/help/#/open_copyright_pro_get_order","target":"_blank"}},[_vm._v(" 如何找到订单号？ "),_c('img',{staticClass:"decoration desk",attrs:{"src":require("@/assets/images/question.png"),"srcset":require("@/assets/images/question@2x.png") + " 2x","alt":"装饰"}})])])}]

export { render, staticRenderFns }
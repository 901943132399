var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"process-ct"},[_c('header',[_c('slogan'),_c('a',{staticClass:"main",attrs:{"href":"/"}}),_c('router-link',{staticClass:"order-query",attrs:{"to":"query"}},[_c('span',[_vm._v("订单查询")])])],1),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"form"},[_c('div',{staticClass:"lf"},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"label"},[_vm._v("存证主体")]),_c('div',{staticClass:"select-group"},[_c('div',{staticClass:"lf",class:{ selected: _vm.subject === 1 },on:{"click":function($event){return _vm.triggerSelect(1)}}},[_vm._v(" 个人 ")]),_c('div',{staticClass:"rt",class:{ selected: _vm.subject === 2 },on:{"click":function($event){return _vm.triggerSelect(2)}}},[_vm._v(" 公司 ")])])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.subject === 2 ? "公司名称" : "姓名"))]),_c('input',{directives:[{name:"autowidth",rawName:"v-autowidth",value:({
            maxWidth: '633px',
            minWidth: '633px',
            comfortZone: 0,
          }),expression:"{\n            maxWidth: '633px',\n            minWidth: '633px',\n            comfortZone: 0,\n          }"},{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"type":"text","placeholder":("请输入" + (_vm.subject === 2 ? '公司名称' : '创作者姓名'))},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"label"},[_vm._v("手机号码")]),_c('input',{directives:[{name:"autowidth",rawName:"v-autowidth",value:({
            maxWidth: '633px',
            minWidth: '633px',
            comfortZone: 0,
          }),expression:"{\n            maxWidth: '633px',\n            minWidth: '633px',\n            comfortZone: 0,\n          }"},{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],attrs:{"type":"number","placeholder":"请输入创作者手机号"},domProps:{"value":(_vm.phone)},on:{"blur":_vm.onNumberInputBlur,"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}})]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.subject === 2 ? "统一社会信用代码" : "身份证号")+" ")]),_c('input',{directives:[{name:"autowidth",rawName:"v-autowidth",value:({
            maxWidth: '633px',
            minWidth: '633px',
            comfortZone: 0,
          }),expression:"{\n            maxWidth: '633px',\n            minWidth: '633px',\n            comfortZone: 0,\n          }"},{name:"model",rawName:"v-model",value:(_vm.idCardNum),expression:"idCardNum"}],attrs:{"type":"text","placeholder":("请输入" + (_vm.subject === 2 ? '统一社会信用代码' : '创作者身份证号'))},domProps:{"value":(_vm.idCardNum)},on:{"blur":_vm.onInputBlur,"input":function($event){if($event.target.composing){ return; }_vm.idCardNum=$event.target.value}}})]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"label"},[_vm._v("作品名称")]),_c('input',{directives:[{name:"autowidth",rawName:"v-autowidth",value:({
            maxWidth: '633px',
            minWidth: '633px',
            comfortZone: 0,
          }),expression:"{\n            maxWidth: '633px',\n            minWidth: '633px',\n            comfortZone: 0,\n          }"},{name:"model",rawName:"v-model",value:(_vm.productname),expression:"productname"}],attrs:{"type":"text","placeholder":"请输入作品名称"},domProps:{"value":(_vm.productname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.productname=$event.target.value}}})]),_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"drop-zone",on:{"click":function($event){return _vm.$refs.uploader.click()},"dragover":function($event){$event.stopPropagation();$event.preventDefault();},"drop":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onFilesDrop.apply(null, arguments)}}},[_vm._m(1)]),_c('input',{ref:"uploader",staticClass:"hidden",attrs:{"type":"file","multiple":false},on:{"change":_vm.onFilesSelect}})]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"label"},[_vm._v("作品指纹计算结果")]),(_vm.fileQueue.length > 0)?_c('ul',{staticClass:"file-quene"},_vm._l((_vm.fileQueue),function(ref,i){
          var file = ref.file;
          var sha256 = ref.sha256;
return _c('li',{key:i},[_c('span',{attrs:{"label":""}},[_vm._v(_vm._s(file.name))]),_vm._v(" | "),_c('span',[_vm._v(_vm._s(_vm.getFileSize(file.size)))]),_vm._v(" | "),_c('span',[_vm._v("Type: "+_vm._s(_vm.getFileType(file.name)))]),_vm._v(" | "),_c('span',[_vm._v("Time spent: "+_vm._s(sha256.timeSpent.toFixed(2))+"s")]),_vm._v(" | "),_c('br'),(sha256.completed)?_c('span',{staticStyle:{"color":"#6362ff","cursor":"pointer"}},[_vm._v("SHA256: "),_c('span',{staticClass:"hash"},[_vm._v(_vm._s(sha256.hash))])]):_c('span',[_c('span',[_vm._v("`%"+_vm._s(sha256.progress)+"`")])])])}),0):_c('p',{staticClass:"un-selected"},[_vm._v("未选择任何文件")])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"label free",on:{"click":function($event){_vm.inputVisible = !_vm.inputVisible}}},[_c('span',[_vm._v("兑换码")]),(_vm.inputVisible)?_c('a',{staticClass:"how",attrs:{"href":"https://mbd.pub/help/#/open_copyright_pro_free_code","target":"_blank"}},[_vm._v("如何获得免费兑换码？")]):_vm._e()]),(_vm.inputVisible)?_c('input',{directives:[{name:"autowidth",rawName:"v-autowidth",value:({
            maxWidth: '633px',
            minWidth: '633px',
            comfortZone: 0,
          }),expression:"{\n            maxWidth: '633px',\n            minWidth: '633px',\n            comfortZone: 0,\n          }"},{name:"model",rawName:"v-model",value:(_vm.free_code),expression:"free_code"}],attrs:{"type":"text","placeholder":"如果你有免费兑换码，请在此处填写"},domProps:{"value":(_vm.free_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.free_code=$event.target.value}}}):_vm._e()]),_c('div',{staticClass:"start row"},[_c('button',{staticClass:"start",class:{ disabled: _vm.hasSaved, loading: _vm.loading },on:{"click":_vm.startProcess}},[_vm._v(" 开始存证 "),(_vm.loading)?_c('spinner-icon',{attrs:{"type":"light"}}):_vm._e()],1)])]),_vm._m(2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('p',{staticClass:"how label"},[_c('span',[_vm._v("作品指纹")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("@/assets/images/product.svg"),"alt":""}}),_c('p',[_vm._v("点击或拖动文件，最大1.5GB")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rt"},[_c('div',{staticClass:"tp box"},[_c('div',{staticClass:"tip"},[_vm._v("特别提醒")]),_c('p',[_vm._v(" 严禁提交他人著作权作品、以及违法、反动、危害社会公共安全等不良信息，否则可能会被国家机构直接追责。 ")])]),_c('div',{staticClass:"bt box"},[_c('p',[_vm._v(" 1.请如实填写各项信息，我们将会提交到合作公证处，如果因为信息填写错误造成存证失败，我们无法退款，请务必注意 ")]),_c('p',[_vm._v(" 2.你的隐私信息除提交给公证处以完成必要证明外，我们不会向任何人泄露，同时亦将按照最高加密级别进行保护 ")]),_c('p',[_vm._v(" 3.目前仅开放原创文艺作品、文学作品、课程使用，其他类目请等待我们后续计划。 ")]),_c('p',[_vm._v(" 4.每次存证仅支持一个文件，单次存证价格为 "),_c('span',{staticClass:"price"},[_vm._v("9.9")]),_vm._v(" 元 ")]),_c('p',[_vm._v(" 5.我们不会保存你的原始文件，但你需要将其妥善保存，以便日后配合存证记录进行维权 ")]),_c('p',[_vm._v(" 6.如遇任何问题，请通过 "),_c('a',{attrs:{"href":"mailto:dk@niucodata.com","rel":"nofollow"}},[_vm._v("dk@niucodata.com")]),_vm._v(" 与我们联系 ")])])])}]

export { render, staticRenderFns }